<template>
<div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            height="700"
            size="small"
            border
			      :span-method="objectSpanMethod"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <el-table-column label="时间">
              <template slot-scope="{ row }">
                {{ search.start_time}}-{{ search.end_time }}
              </template>
            </el-table-column>
            <ElTableColumn label="市场" prop="market_name" />
            <ElTableColumn label="店面" prop="shop_name" />
			      <ElTableColumn label="店面" prop="shop_name" />
            <!-- <ElTableColumn label="项目分类" prop="goods_type" /> -->
            <el-table-column label="项目大类">
              <template slot-scope="{ row }">
                {{ goods_type[row.goods_type]}}
              </template>
            </el-table-column>
            <ElTableColumn label="项目明细" prop="goods_name" />
            <ElTableColumn label="购买人数" prop="number" />
			      <ElTableColumn label="复购次数" prop="repurchase_num" />
            <ElTableColumn label="销售金额" prop="total_amount" />
          </el-table>
          
        </el-col>
      </el-row>
  </div>
</template>
<script>
import pagination from '@/components/Pagination'
import { productAnalysis } from "@/api/statistics";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
}
export default {
  name: 'productAnalysisType',
   components: {
    pagination
  },
  data () {
    return {
      list: [], // 数据展示
      page: new Page(), // 分页
      type:'',
      search:new Search(),
      options: [
        {
          value: 1,
          label: '补水保湿'
        }, 
        {
          value: 2,
          label: '祛黄提亮'
        }, 
        {
          value: 3,
          label: '控油祛痘'
        }, 
        {
          value: 4,
          label: '安舒修复'
        }, 
        {
          value: 5,
          label: '面部仪器'
        },
        {
          value: 6,
          label: '面部手法'
        }, 
        {
          value: 7,
          label: '身体仪器'
        }, 
        {
          value: 8,
          label: '身体手法'
        },
      ],
      option_value:[1,2,3,4,5,6,7,8],
      goods_type:{
        1:"补水保湿",
        2:"祛黄提亮",
        3:"控油祛痘",
        4:"安舒修复",
        5:"面部仪器",
        6:"面部手法",
        7:"身体仪器",
        8:"身体手法"
      }
  
    }
  },
  mounted () {
    //this.type = this.$route.query.type
    //console.log(this.$route.query);
    this.getList();
  },
  methods: {
    getList() {
      this.search.start_time = this.$route.query.start_time;
      this.search.end_time = this.$route.query.end_time;
      productAnalysis({ ...this.page, ...this.$route.query}).then(res => {
        this.list = res.data;
        console.log(res);
        // this.list.forEach((val) => {
        //   val['shop_name'] = res.data.shop_name;
        // });
        this.page.total = res.data.dataCount;
      });
    },
	
  }
}
</script>

<style lang="scss" scoped>
.reveal{
  text-align: right;
  padding: 20px 0;
  .peril{
    font-weight: bold;
  }
}
.btnBox{
  text-align: right;
}
</style>
